<script>
export default {
  data() {
    return {  
        
    }
  }, 
  props:['windowScroll'],
  computed: {
    publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : {} },
    tabs() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.tabs : {} },
    availableBookings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.availableBookings : {} },
    customerName() { return this.$store.state.publicPage != null ? this.$store.state.publicPage.customerName : '' },
    settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : null },
    hasSettings() { return this.settings },
    selectedPage() { return this.$route.name }
  },
  watch:
  {
    customerName(newvalue)
    {
      document.title = newvalue
    }
  },
  methods:
  {
    goTopage(page, bookingItem)
    {
      $(window).scrollTop(0);   
      if(page == 'bookingHoursAdd' || page == 'bookingDaysAdd')
      {
        this.$router.push({ name: page, params: { id: this.$route.params.id, bookingid: bookingItem.id } })
      }
      else
      {
        this.$router.push({ name: page, params: { id: this.$route.params.id } })
      }     
    },
    goToTab(id)
    {
        this.$router.push({ name: 'tab', params: { id: this.$route.params.id, tabid: id } })
    }
  }
}
</script>
<template>
  <section class="Menu" v-fade-in>
    <div class="p" :class="[windowScroll > 200 ? 'Menu-addedWhite' : 'Menu-noBg']">
      <ul>
        <li @click="goTopage('home')" :style="{ 'background-color': selectedPage == 'home' ? publicPage.background_color : '' }">
           Hem     
        </li>
        <li @click="goTopage('community')" :style="{ 'background-color': selectedPage == 'community' ? publicPage.background_color : '' }">
          {{hasSettings ? settings.Community.singular : '' }}         
        </li>
        <li v-for="tab in tabs" @click="goToTab(tab.id)" :style="{ 'background-color': selectedPage == 'tab' && this.$route.params.tabid == tab.id  ? publicPage.background_color : '' }">
          {{ tab.name }}
        </li>
        <li v-if="publicPage.include_image_gallery" @click="goTopage('imageGallery')" :style="{ 'background-color': selectedPage == 'imageGallery' ? publicPage.background_color : '' }">
          {{hasSettings ? settings.ImageGallery.singular : '' }}
        </li>
        <li v-if="availableBookings.length == 1" :style="{ 'background-color': selectedPage == 'bookingHoursAdd' || selectedPage == 'bookingDaysAdd'  ? publicPage.background_color : '' }">
          <span v-if=" availableBookings[0].duration_unit == 'Timmar'" @click="goTopage('bookingHoursAdd', availableBookings[0])">{{ availableBookings[0].name }}</span>     
          <span v-if=" availableBookings[0].duration_unit == 'Dagar'" @click="goTopage('bookingDaysAdd', availableBookings[0])">{{ availableBookings[0].name }}</span>     
        </li>
        <li v-if="availableBookings.length > 1" :style="{ 'background-color':selectedPage == 'bookings' ? publicPage.background_color : '' }"  @click="goTopage('bookings')">
          {{hasSettings ? settings.BookingItem.plural : '' }}        
        </li>
        <li v-if="publicPage.include_contact"  @click="goTopage('contact')" :style="{ 'background-color': selectedPage == 'contact' ? publicPage.background_color : '' }">
          Kontakta Oss      
        </li>  
      </ul>
    </div>
  </section>
</template>
<style scoped>
.Menu
{
  width:100%; 
  height: 120px; 
  color: white;
  position: fixed;
  top:0px;
  z-index: 20; 
  font-family: 'Raleway', sans-serif;
  text-transform: capitalize;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.p {
  display:flex;
  justify-content: center;

}
.Menu-addedWhite
{
  background: rgba(255,255,255,0.97);
  border-bottom: 1px solid #ccc;
  transition: 0.3s;
}
.Menu-noBg
{
  background: none;
  transition: 0.3s;
}
ul{
  text-align: center;
  margin: auto;
  padding: 0px;
}
li{ 
  display: inline-block; 
  vertical-align:top;
  padding: 26px 40px;
  text-align: center;
  font-size: 14pt; 
  font-weight: bold; 
  cursor: pointer;
}
@media (-webkit-device-pixel-ratio: 1.25) {
    li{ 
      display: inline-block; 
      padding: 15px 25px;
      text-align: center;
      font-size: 11pt; 
      font-weight: bold; 
      cursor: pointer;
    }
}


@media only screen and (max-width: 1200px) {
  li{ 

  font-size: 12pt; 
  padding: 26px 30px;
}
}
</style>
