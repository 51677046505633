<script>
import config from "@/helpers/config"
export default {
    components: { },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        tabs() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.tabs : null },   
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        height() { return window.innerHeight/1.3 + 'px' },
        tab() { return this.tabs.find(x => x.id == this.$route.params.tabid) }
  }
}
</script>
<template>
   <section v-if="tabs != null && tab" v-fade-in>
        <div class="top-public" :style="{ 'background-image': tab.image_name != null ? 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+ tab.image_name + ')' : '', 'height': height }">
        
        </div>
        <div :style="{ 'background-color': publicPage.background_color }">
            <div class="text-section">
                <h1 :style="{ 'color': publicPage.header_color }">{{ tab.name }}</h1>
                <div v-html="this.$adaptHtml.adaptHtml(tab.text, publicPage.header_color)"></div>
            </div>
        </div>
   </section>
</template>
<style scoped>
    section {
        color: black;
    }
</style>